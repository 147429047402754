<template>
  <b-overlay
    variant="light"
    :show="loading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-card>
      <div class="flex items-start">
        <b-button
          v-if="editMode"
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="goBack"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <div class="font-semibold text-black mb-2 text-2xl">
          Gudangku
        </div>
      </div>
      <div
        v-if="!editMode"
        class="space-y-6"
      >
        <MitraGudangKompack v-if="$store.state.auth.userData.is_kompack === 1" />

        <div class="border rounded-lg">
          <div class="font-semibold text-black text-xl p-6">
            Gudang Pribadi
          </div>
          <div
            v-if="$store.state.auth.userData.is_kompack === 0"
            class="bg-[#4285F4] w-full py-[14px] px-[32px] text-white mb-[24px] rounded-[2px] relative"
          >
            <div class="absolute left-6 top-3">
              <img
                src="https://storage.googleapis.com/komerce/assets/LP-Kompack/thinsmooth.svg"
                alt=""
              >
            </div>
            <div class="font-semibold">
              Integrasikan Akunmu dengan <img
                src="https://storage.googleapis.com/komerce/assets/LP-Kompack/kompack-wht-logo-only.svg"
                class="inline-flex"
                alt=""
              >Kompack biar lebih Hemat!
            </div>
            <div class="!font-[400]">
              Nikmati layanan pergudangan yang mudah dan murah mulai dari <span class="text-[#FBE205] !font-[500]">Rp3.000</span> tanpa biaya sewa bulanan. <a
                href="https://kompack.id/user-komship"
                class="text-white font-semibold underline"
                target="_blank"
              >Klik di sini</a>
            </div>
          </div>
          <b-img
            v-if="!formAddAddress && warehouseItems.length > 0 && editMode === false"
            v-b-modal="'warehouse-options'"
            src="@/assets/images/icons/add-circle.svg"
            class="cursor-pointer button__add__warehouse"
          />
          <div
            v-if="formAddAddress === false && editMode === false"
            :class="warehouseItems.length > 0 ? '' : 'h-[600px] justify-content-center align-items-center'"
          >
            <b-col
              v-if="warehouseItems.length > 0"
              cols="12"
              class="px-0"
            >
              <b-table
                :items="warehouseItems"
                :fields="warehouseFields"
                responsive
              >
                <template #cell(name)="data">
                  <div
                    class="d-flex align-items-center"
                    style="min-width: 200px!important;"
                  >
                    <b-img
                      :id="`warehouse-` + data.item.mitra_id"
                      :src="data.item.warehouse_type === 'Mitra Kompack' ? 'https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg' : data.item.image_logo_url"
                      class="mr-50"
                    />
                    <span class="text-black font-medium">{{ data.value }}</span>
                  </div>
                  <b-popover
                    v-if="data.item.warehouse_type === 'Mitra Kompack'"
                    triggers="hover"
                    :target="`warehouse-` + data.item.mitra_id"
                    placement="top"
                    custom-class="my-popover-class"
                  >
                    Mitra Gudang Kompack
                  </b-popover>
                </template>
                <template #cell(city)="data">
                  {{ data.value }}
                </template>
                <template #cell(pic_name)="data">
                  {{ data.value }}
                </template>
                <template #cell(product_total)="data">
                  {{ data.value }}
                </template>
                <template #cell(action)="data">
                  <b-button
                    v-if="data.item.warehouse_type !== 'Mitra Kompack'"
                    variant="flat-info"
                    class="p-0"
                    @click="editAddress(data)"
                  >
                    Lihat Detail
                  </b-button>
                  <b-button
                    v-else
                    variant="flat-info"
                    class="btn-icon"
                    @click="handleDetailMitra(data.item.mitra_id)"
                  >
                    Lihat Detail
                  </b-button>
                  <div
                    v-if="data.item.is_default === 1"
                    class="d-flex align-items-center ml-50"
                  >
                    <b-img
                      src="https://storage.googleapis.com/komerce/assets/svg/gudang-utama-orange.svg"
                      class="mr-50"
                    />
                    <span class="text-primary">[Utama]</span>
                  </div>
                </template>
              </b-table>
            </b-col>
            <div v-if="warehouseItems.length === 0">
              <b-row class="justify-content-center">
                <b-img
                  v-b-modal="'warehouse-options'"
                  class="cursor-pointer"
                  src="@/assets/images/warehouse/icons/plus-icon.svg"
                />
              </b-row>
              <b-row class="justify-content-center mb-50">
                <h4 class="text-black ml-50">
                  Tambah Gudang
                </h4>
              </b-row>
              <b-row class="justify-content-center">
                <b-col
                  cols="7"
                  class="text-center"
                >
                  <span class="text-muted">
                    Kamu belum memiliki alamat atau gudang yang terdaftar. Silahkan daftarkan alamat/gudang untuk alamat penjemputan
                  </span>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>

      <div name="fade">
        <div
          v-if="formAddAddress === true"
          class=" "
        >
          <b-col
            cols="12"
            class="border rounded-lg !p-6"
          >
            <b-row class="d-flex  mb-1">
              <b-col md="6">
                <h4 class="text-black">
                  <strong>Tambah Alamat</strong>
                </h4>
              </b-col>
            </b-row>
            <validation-observer
              ref="formRulesAdd"
            >
              <b-form class="">
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-1 ml-16 pl-50"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b-form-group label-cols-md="4">
                          <template #label>
                            <h4 class="text-black">
                              Nama Tempat<span class="text-primary">*</span>
                            </h4>
                          </template>
                          <validation-provider
                            #default="{errors}"
                            name="Nama Alamat"
                            rules="required"
                          >
                            <b-form-input
                              v-model="fieldAddAddressName"
                              placeholder="Contoh: Gudang Jawa Barat"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col
                    cols="12"
                    class="mb-1 ml-16 pl-50"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b-form-group label-cols-md="4">
                          <template #label>
                            <h4 class="text-black">
                              Kelurahan/Kecamatan<span class="text-primary">*</span>
                            </h4>
                          </template>
                          <validation-provider
                            #default="{errors}"
                            name="Kelurahan/Kecamatan"
                            rules="required"
                          >
                            <v-select
                              v-model="fieldAddOrigin"
                              :options="itemsOriginEdit"
                              label="label"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Masukkan Kelurahan/Kecamatan"
                              @search="onSearchOrigin"
                            />
                            <small
                              v-if="errors[0]"
                              class="text-danger"
                            >*Kelurahan/Kecamatan harus diisi</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-3 ml-16 pl-50"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b-form-group label-cols-md="4">
                          <template #label>
                            <h4 class="text-black">
                              Alamat Detail<span class="text-primary">*</span>
                            </h4>
                            <small>
                              Alamat ini akan jadi petunjuk kurir saat mau
                              jemput barang. Pastikan kamu isi dengan detail ya.
                            </small>
                          </template>
                          <validation-provider
                            #default="{errors}"
                            name="Alamat Detail"
                            :rules="{ required: true, min: 50 }"
                          >
                            <b-form-textarea
                              v-model="fieldAddAddressDetail"
                              placeholder="Contoh: Jl. Raya Tamansari, Kompleks Karangwuni, Desa, Dusun I, Tamansari, Karangmoncol, Kabupaten Purbalingga, Jawa Tengah 53355"
                              rows="3"
                              :state="errors.length > 0? false : null"
                              :formatter="formatDetailAddress"
                              @keypress="validateInputDetail"
                              @paste="pasteDetailAddress"
                            />
                            <b-row class="justify-content-between">
                              <small class="text-primary ml-1 mt-50">{{
                                errors[0]
                              }}</small>
                              <small class="mr-1 mt-50">
                                <small
                                  v-if="messageErrorLengthAddress"
                                  class="text-primary"
                                >
                                  *hindari menggunakan simbol (/) (=) (:) (;)
                                </small>
                                {{ fieldAddAddressDetail.length }}/85
                              </small>
                            </b-row>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h4 class="text-black">
                      <strong>
                        Penanggung Jawab
                      </strong>
                    </h4>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-1 ml-16 pl-50"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b-form-group label-cols-md="4">
                          <template #label>
                            <h4 class="text-black">
                              Nama<span class="text-primary">*</span>
                            </h4>
                          </template>
                          <validation-provider
                            #default="{errors}"
                            name="PIC"
                            rules="required"
                          >
                            <b-form-input
                              v-model="fieldAddPicName"
                              placeholder="Masukkan Nama Penanggung Jawab Gudang"
                              :state="errors.length > 0 ? false : null"
                              :formatter="formatName"
                              @keypress="validateInputName"
                            />
                            <small
                              v-if="errors[0]"
                              class="text-primary mt-50"
                            >*Nama Penanggung Jawab harus diisi</small>
                          </validation-provider>
                          <b-row class="justify-content-end">
                            <small class="mr-1 mt-50">
                              <small
                                v-if="messageErrorLengthName"
                                class="text-primary"
                              >
                                *hindari menggunakan simbol (/) (=) (:) (;)
                              </small>
                              {{ fieldAddPicName.length }}/30
                            </small>
                          </b-row>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    class="ml-16 pl-50"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b-form-group label-cols-md="4">
                          <template #label>
                            <h4 class="text-black">
                              No. HP<span class="text-primary">*</span>
                            </h4>
                            <small>
                              Kurir yang jemput akan menghubungi nomor ini
                            </small>
                          </template>
                          <phone-input
                            :check-wa="true"
                            @update:dataForParent="setCustomerPhone"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col
                    md="12"
                    class="ml-16 pl-50"
                  >
                    <b-form-checkbox
                      v-model="isDefault"
                      @change="changeDefaultAddress"
                    >
                      <span class="text-black">Jadikan sebagai alamat utama</span>
                    </b-form-checkbox>
                  </b-col>

                  <transition name="fade">
                    <b-col
                      md="12"
                      class="d-flex justify-content-end mt-1 pb-1"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="outline-primary"
                        class="mr-1"
                        @click.prevent="removeFormAddress"
                      >
                        Hapus
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="primary"
                        class="mr-1"
                        :disabled="messageErrorPhone || fieldAddAddressDetail.length < 50"
                        @click.prevent="submitAddress"
                      >
                        <b-spinner
                          v-if="loadingSubmit"
                          variant="light"
                          small
                        />
                        Simpan
                      </b-button>
                    </b-col>
                  </transition>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
        </div>
      </div>

      <!-- Edit -->
      <div name="fade">
        <div
          v-if="editMode"
          class=" "
        >
          <b-col
            cols="12"
            class="border rounded-lg !p-6"
          >
            <b-row class="d-flex  mb-1">
              <b-col md="6">
                <h4 class="text-black">
                  <strong>Edit Alamat</strong>
                </h4>
              </b-col>
            </b-row>
            <validation-observer
              ref="formRulesEdit"
            >
              <b-form class="">
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-1 ml-16 pl-50"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b-form-group label-cols-md="4">
                          <template #label>
                            <h4 class="text-black">
                              Nama Tempat<span class="text-primary">*</span>
                            </h4>
                          </template>
                          <validation-provider
                            #default="{errors}"
                            name="Nama Alamat"
                            rules="required"
                          >
                            <b-form-input
                              v-model="addressName"
                              placeholder="Contoh: Gudang Jawa Barat"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col
                    cols="12"
                    class="mb-1 ml-16 pl-50"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b-form-group label-cols-md="4">
                          <template #label>
                            <h4 class="text-black">
                              Kelurahan/Kecamatan<span class="text-primary">*</span>
                            </h4>
                          </template>
                          <validation-provider
                            #default="{errors}"
                            name="Kelurahan/Kecamatan"
                            rules="required"
                          >
                            <v-select
                              v-model="originValue"
                              :options="itemsOriginEdit"
                              label="label"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Masukkan Kelurahan/Kecamatan"
                              @search="onSearchOrigin"
                            />
                            <small
                              v-if="errors[0]"
                              class="text-danger"
                            >*Kelurahan/Kecamatan harus diisi</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-3 ml-16 pl-50"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b-form-group label-cols-md="4">
                          <template #label>
                            <h4 class="text-black">
                              Alamat Detail<span class="text-primary">*</span>
                            </h4>
                            <small>
                              Alamat ini akan jadi petunjuk kurir saat mau
                              jemput barang. Pastikan kamu isi dengan detail ya.
                            </small>
                          </template>
                          <validation-provider
                            #default="{errors}"
                            name="Alamat Detail"
                            :rules="{ required: true, min: 50 }"
                          >
                            <b-form-textarea
                              v-model="addressDetail"
                              placeholder="Contoh: Jl. Raya Tamansari, Kompleks Karangwuni, Desa, Dusun I, Tamansari, Karangmoncol, Kabupaten Purbalingga, Jawa Tengah 53355"
                              rows="3"
                              :state="errors.length > 0 ? false : null"
                              :formatter="formatDetailAddress"
                              @keypress="validateInputDetail"
                              @paste="pasteDetailAddress"
                            />
                            <b-row class="justify-content-between">
                              <small class="text-primary ml-1 mt-50">{{
                                errors[0]
                              }}</small>
                              <small class="mr-1 mt-50">
                                <small
                                  v-if="messageErrorLengthAddress"
                                  class="text-primary"
                                >
                                  *hindari menggunakan simbol (/) (=) (:) (;)
                                </small>
                                {{ addressDetail.length }}/85
                              </small>
                            </b-row>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h4 class="text-black">
                      <strong>
                        Penanggung Jawab
                      </strong>
                    </h4>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-1 ml-16 pl-50"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b-form-group label-cols-md="4">
                          <template #label>
                            <h4 class="text-black">
                              Nama<span class="text-primary">*</span>
                            </h4>
                          </template>
                          <validation-provider
                            #default="{errors}"
                            name="PIC"
                            rules="required"
                          >
                            <b-form-input
                              v-model="picName"
                              placeholder="Masukkan Nama Penanggung Jawab Gudang"
                              :formatter="formatName"
                              :state="errors.length > 0 ? false : null"
                              @keypress="validateInputName"
                            />
                            <small
                              v-if="errors[0]"
                              class="text-primary mt-50"
                            >*Nama Penanggung Jawab harus diisi</small>
                            <b-row class="justify-content-end">
                              <small class="mr-1 mt-50">
                                <small
                                  v-if="messageErrorLengthName"
                                  class="text-primary"
                                >
                                  *hindari menggunakan simbol (/) (=) (:) (;)
                                </small>
                                {{ picName.length }}/30
                              </small>
                            </b-row>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    class="ml-16 pl-50"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b-form-group label-cols-md="4">
                          <template #label>
                            <h4 class="text-black">
                              No. HP<span class="text-primary">*</span>
                            </h4>
                            <small>
                              Kurir yang jemput akan menghubungi nomor ini
                            </small>
                          </template>
                          <phone-input
                            :value-phone="phoneUser"
                            :check-wa="true"
                            @update:dataForParent="setCustomerPhone"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col
                    md="12"
                    class="ml-16 pl-50"
                  >
                    <b-form-checkbox
                      v-model="isDefault"
                      @change="changeDefaultAddress"
                    >
                      <span class="text-black">Jadikan sebagai alamat utama</span>
                    </b-form-checkbox>
                  </b-col>

                  <transition name="fade">
                    <b-col
                      md="12"
                      class="d-flex justify-content-end mt-1 pb-1"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="outline-primary"
                        class="mr-1"
                        @click.prevent="confirmDelete(editIdAddress)"
                      >
                        Hapus
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="primary"
                        class="mr-1"
                        :disabled="messageErrorPhone || addressDetail.length < 50"
                        @click.prevent="submitUpdateAddress"
                      >
                        <b-spinner
                          v-if="loadingSubmit"
                          variant="light"
                          small
                        />
                        Simpan
                      </b-button>
                    </b-col>
                  </transition>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
        </div>
      </div>
    </b-card>

    <!-- confirm Delete Address -->
    <b-modal
      ref="modal-confirm-delete-address"
      no-close-on-backdrop
      hide-header-close
      hide-header
      hide-footer
      modal-class="modal-primary"
      centered
      title="Primary Modal"
    >
      <b-container class="flex flex-col items-center gap-4 py-[44px] justify-center text-center">
        <b-row>
          <b-img
            width="100"
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            alt="alert"
          />
        </b-row>

        <b-row>
          <h4 class="font-[600]">
            Hapus Gudang
          </h4>
        </b-row>

        <b-row>
          <p>
            Menghapus gudang ini akan menghilangkan gudang dari list gudangku
          </p>
        </b-row>

        <b-row class="flex gap-2 w-full flex-row-reverse">
          <b-button
            variant="primary"
            class="flex-[50%] md:flex-[45%]"
            :disabled="loading"
            @click="deleteAddress"
          >
            <b-spinner
              v-if="loading"
              variant="light"
              class="ml-1"
              small
            />
            Hapus
          </b-button>
          <b-button
            variant="outline-primary"
            class="flex-[50%] md:flex-[45%]"
            @click="closeConfirmDelete"
          >
            Batal
          </b-button>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal
      ref="modal-validate-address"
      no-close-on-backdrop
      hide-header-close
      hide-header
      modal-class="modal-primary"
      centered
      title="Primary Modal"
    >
      <b-col
        md="12"
        class="d-flex justify-content-center pt-3"
      >
        <b-img
          width="100"
          src="@core/assets/image/icon-popup-warning.png"
        />
      </b-col>

      <b-col class="text-center mt-1">
        <h4>
          Kamu tidak dapat menghapus alamat penjemputan yang menjadi alamat
          utama.
        </h4>
      </b-col>

      <template #modal-footer>
        <b-col
          md="12"
          class="d-flex justify-content-center pb-2"
        >
          <b-button
            variant="primary"
            class="mr-50"
            @click="closeAlertCannotDelete"
          >
            Oke
          </b-button>
        </b-col>
      </template>
    </b-modal>
    <b-modal
      ref="modal-validate-address-stilluse"
      hide-header-close
      hide-header
      hide-footer
      modal-class="modal-primary"
      centered
      title="Primary Modal"
    >
      <b-container class="flex flex-col items-center gap-4 py-[44px] justify-center text-center">
        <b-row>
          <b-img
            width="100"
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            alt="alert"
          />
        </b-row>

        <b-row>
          <h4 class="font-[600]">
            Hapus Gudang
          </h4>
        </b-row>

        <b-row>
          <p>
            Kamu tidak dapat menghapus alamat penjemputan karena sudah terhubung dengan orderku
          </p>
        </b-row>

        <b-button
          variant="primary"
          block
          @click="handleCloseModal"
        >
          Oke
        </b-button>
      </b-container>
    </b-modal>
    <b-modal
      id="warehouse-options"
      ref="warehouse-options"
      hide-header
      hide-footer
      centered
    >
      <div class="py-1">
        <h2 class="text-[20px] text-center font-semibold mb-2">
          Tambahkan Gudang
        </h2>
        <b-row>
          <b-col cols="6">
            <b-card
              class="border-primary"
              role="button"
              style="height:180px"
              @click="selectWarehouse('ownWarehouse', userData.is_kompack)"
            >
              <b-img
                src="@/assets/images/banner/private-warehouse.svg"
                class="m-auto"
              /><br>
              <p class="text-[16px] text-[#626262] text-center">
                Gudang Pribadi
              </p>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card
              id="partnerWarehouse"
              class="border-primary p-0"
              role="button"
              style="height:180px"
              @click="selectWarehouse('partnerWarehouse', userData.is_kompack)"
            >
              <b-img
                src="@/assets/images/banner/kompack-warehouse.svg"
                class="m-auto"
                :class="(userData.is_kompack !== 1) ? `grayscale` : ``"
              /><br>
              <div class="d-flex text-center -m-1">
                <div class="text-[14px] text-[#626262]">
                  Mitra Gudang
                </div>
                <b-img
                  src="@/assets/images/logo/kompack-logo.svg"
                  class="m-auto kompack-logo ml-[5px]"
                  style="height:14px"
                />
              </div>
              <b-popover
                v-if="(userData.is_kompack !== 1)"
                target="partnerWarehouse"
                variant="primary"
                triggers="hover"
                placement="bottom"
              >
                <small class="text-[#828282]">
                  Simpan barangmu di mitra gudang Kompack. Nikmati layanan pergudangan yang mudah dan murah mulai dari Rp.3000 aja tanpa biaya sewa bulanan. Yuk Integrasikan sekarang!
                </small>
              </b-popover>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-modal>

  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BModal,
  BImg,
} from 'bootstrap-vue'
import phoneInput from '@/views/components/input/phoneInput.vue'
import secureLs from '@/libs/secureLs'
import MitraGudangKompack from '../../kompack/gudang/gudangku/MitraGudangKompack.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BModal,
    BImg,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    phoneInput,
    MitraGudangKompack,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: secureLs.getItem('userData'),

      loading: false,
      loadingSubmit: false,
      isDefault: false,
      dataAddress: [],
      itemsOrigin: [],
      codeOrigin: [],
      fieldOrigin: '',
      formAddAddress: false,

      // Edit Mode
      editMode: false,
      editIdAddress: null,
      originValue: '',

      addressName: '',
      codeOriginEdit: [],
      itemsOriginEdit: [],
      addressDetail: '',
      picName: '',
      phoneUser: '',

      // Add Address
      fieldAddAddressName: '',
      fieldAddAddressDetail: '',
      fieldAddPicName: '',
      customerPhone: '',
      fieldAddOrigin: '',

      tes: [],
      dataDelete: null,

      // Validation
      required,

      dataIsDefault: 0,

      handleOldOrigin: false,

      messageErrorLengthAddress: false,
      messageErrorLengthName: false,
      messageErrorPhone: true,

      warehouseItems: [],
      warehouseFields: [
        {
          key: 'name',
          label: 'Nama Gudang',
          thClass: '!bg-[#f4f4f4] text-black text-capitalize font-semibold !text-[14px]',
          tdClass: 'bg-white',
          tdStyle: {
            backgroundColor: 'white',
            borderBottom: '0px',
            verticalAlign: 'text-top',
          },
          thStyle: {
            backgroundColor: 'white',
            borderBottom: '0px',
          },
        },
        {
          key: 'city',
          label: 'Kota/Kabupaten',
          thClass: '!bg-[#f4f4f4] text-black text-capitalize font-semibold !text-[14px]',
          tdClass: 'bg-white',
          tdStyle: {
            backgroundColor: 'white',
            borderBottom: '0px',
            verticalAlign: 'text-top',
          },
          thStyle: {
            backgroundColor: 'white',
            borderBottom: '0px',
          },
        },
        {
          key: 'pic_name',
          label: 'Penanggung Jawab',
          thClass: '!bg-[#f4f4f4] text-black text-capitalize font-semibold !text-[14px]',
          tdClass: 'bg-white',
          tdStyle: {
            backgroundColor: 'white',
            borderBottom: '0px',
            verticalAlign: 'text-top',
          },
          thStyle: {
            backgroundColor: 'white',
            borderBottom: '0px',
          },
        },
        // {
        //   key: 'product_total',
        //   label: 'Produk',
        //   thClass: '!bg-[#f4f4f4] text-black text-capitalize font-semibold !text-[14px]',
        //   tdClass: 'bg-white',
        //   tdStyle: {
        //     backgroundColor: 'white',
        //     borderBottom: '0px',
        //     verticalAlign: 'text-top',
        //   },
        //   thStyle: {
        //     backgroundColor: 'white',
        //     borderBottom: '0px',
        //   },
        // },
        {
          key: 'action',
          label: 'Aksi',
          thClass: '!bg-[#f4f4f4] text-black text-capitalize font-semibold !text-[14px]',
          tdClass: 'bg-white',
          tdStyle: {
            backgroundColor: 'white',
            borderBottom: '0px',
            verticalAlign: 'text-top',
          },
          thStyle: {
            backgroundColor: 'white',
            borderBottom: '0px',
          },
        },
      ],
      items: [],
    }
  },
  mounted() {
    this.getAddress()
  },
  methods: {
    handleDetailMitra(id) {
      this.$router.push({
        path: `/detail-gudang-kompack/${id}`,
      })
    },

    getAddress() {
      this.loading = true
      this.$http_komship
        .get('/v1/komship/warehouse')
        .then(async response => {
          const { data } = response.data
          this.items = data
          this.dataAddress = data
          this.loading = false
          this.warehouseItems = data.filter(item => item.mitra_id === null)
        })
        .catch(() => {
          this.loading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    submitAddress() {
      this.loadingSubmit = true
      this.$refs.formRulesAdd.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('_method', 'post')
          formData.append('address_name', this.fieldAddAddressName)
          formData.append('origin_code', this.fieldAddOrigin.origin_code)
          formData.append('address_detail', this.fieldAddAddressDetail)
          formData.append('pic', this.fieldAddPicName)
          formData.append('zip_code', this.fieldAddOrigin.zip_code)
          formData.append('destination_id', this.fieldAddOrigin.id)
          formData.append('phone', this.customerPhone)
          formData.append('is_default', this.dataIsDefault)

          this.$http_komship
            .post('/v1/address/store', formData)
            .then(response => {
              if (response.data.code !== 400) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      text: 'Success menambahkan alamat pickup',
                      variant: 'success',
                    },
                  },
                  2000,
                )
                this.loadingSubmit = false
                this.formAddAddress = false
                this.getAddress()
              } else {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Gagal',
                      icon: 'AlertCircleIcon',
                      text:
                        'Gagal menambahkan alamat pickup, silahkan coba lagi!',
                      variant: 'danger',
                    },
                  },
                  2000,
                )
                this.loadingSubmit = false
                this.formAddAddress = false
                this.getAddress()
              }
            })
            .catch(() => {
              this.loadingSubmit = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Gagal',
                    icon: 'AlertCircleIcon',
                    text: 'Gagal menambahkan alamat, silahkan coba lagi',
                    variant: 'danger',
                  },
                },
                2000,
              )
            })
        } else {
          this.loadingSubmit = false
        }
      })
    },
    checkAddressIsDefault(data) {
      let result = false
      if (data.is_default > 0) {
        result = true
      }
      return result
    },
    addAddress() {
      this.fieldAddAddressName = ''
      this.fieldAddOrigin = ''
      this.fieldAddAddressDetail = ''
      this.fieldAddPicName = ''
      this.customerPhone = ''
      this.isDefault = false
      this.formAddAddress = true
      this.editMode = false
    },
    removeFormAddress() {
      this.formAddAddress = false
    },
    submitUpdateAddress() {
      this.loadingSubmit = true
      // eslint-disable-next-line dot-notation
      this.$refs['formRulesEdit'].validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('_method', 'put')
          formData.append('address_name', this.addressName)
          if (this.originValue !== null || this.originValue !== '') {
            formData.append('origin_code', this.originValue.origin_code)
            formData.append('destination_id', this.originValue.id)
            formData.append('zip_code', this.originValue.zip_code)
          }
          formData.append('address_detail', this.addressDetail)
          formData.append('pic', this.picName)
          formData.append('phone', this.phoneUser)
          formData.append('is_default', this.dataIsDefault)
          this.$http_komship
            .post(`/v1/address/update/${this.editIdAddress}`, formData)
            .then(() => {
              this.tes = []
              this.handleOldOrigin = false
              this.loadingSubmit = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'CheckIcon',
                  text: 'Success update satu alamat pickup',
                  variant: 'success',
                },
              })
              this.editMode = false
              this.getAddress()
            })
            .catch(() => {
              this.loadingSubmit = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Failed',
                    icon: 'AlertCircleIcon',
                    text: 'Gagal update alamat pickup, silahkan coba lagi!',
                    variant: 'danger',
                  },
                },
                2000,
              )
            })
        } else {
          this.loadingSubmit = false
        }
      })
    },
    editAddress(data) {
      this.editMode = true
      if (this.warehouseItems[data.index].warehouse_type === 'Mitra Kompack') {
        this.editIdAddress = this.warehouseItems[data.index].mitra_id
      } else {
        this.editIdAddress = this.warehouseItems[data.index].id
      }
      this.addressName = this.warehouseItems[data.index].name
      this.addressDetail = this.warehouseItems[data.index].detail_address
      this.picName = this.warehouseItems[data.index].pic_name
      this.phoneUser = this.warehouseItems[data.index].pic_phone
      if (this.warehouseItems[data.index].is_default === 0) {
        this.isDefault = false
      } else {
        this.isDefault = true
      }
      this.$http_komship
        .get(`/v1/destination?destination_id=${this.warehouseItems[data.index].destination_id}`)
        .then(response => {
          if (response.data.data !== null) {
            this.originValue = response.data.data
          }
        })
    },
    confirmDelete(data) {
      this.dataDelete = data
      this.$refs['modal-confirm-delete-address'].show()
    },
    deleteAddress() {
      if (this.dataDelete.is_default !== 1) {
        this.loading = true
        this.$http_komship
          .delete(`/v2/address/delete/${this.dataDelete}`)
          .then(() => {
            this.getAddress()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sukses',
                icon: 'CheckIcon',
                text: 'Berhasil menghapus gudang',
                variant: 'success',
              },
            })
            this.editMode = false
          })
          .catch(() => {
            this.$refs['modal-validate-address-stilluse'].show()
          })
          .finally(() => {
            this.loading = false
            this.$refs['modal-confirm-delete-address'].hide()
          })
      } else {
        this.$refs['modal-confirm-delete-address'].hide()
        this.$refs['modal-validate-address'].show()
      }
    },
    onSearchOrigin(search, loading) {
      if (search.length) {
        this.searchOrigin(loading, search, this)
      }
    },
    searchOrigin: _.debounce((loading, search, that) => {
      loading(true)
      that.loadOrigin(search).then(() => loading(false))
    }, 500),
    loadOrigin(search) {
      return this.$http_komship
        .get(`/v1/destination?search=${search}`)
        .then(response => {
          this.itemsOriginEdit = response.data.data.data
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          })
        })
    },
    changeDefaultAddress() {
      if (this.isDefault === false) {
        this.dataIsDefault = 0
      } else {
        this.dataIsDefault = 1
      }
    },
    changeEditMode() {
      this.editMode = false
    },
    closeConfirmDelete() {
      this.$refs['modal-confirm-delete-address'].hide()
    },
    closeAlertCannotDelete() {
      this.$refs['modal-validate-address'].hide()
    },
    handleCloseModal() {
      this.$refs['modal-validate-address-stilluse'].hide()
    },
    handleSelectOrigin(data) {
      this.originValue = data
    },
    formatDetailAddress(e) {
      return String(e).substring(0, 85)
    },
    formatName(e) {
      return String(e).substring(0, 30)
    },
    validateInputDetail(e) {
      if (
        e.keyCode === 47
        || e.keyCode === 61
        || e.keyCode === 58
        || e.keyCode === 59
      ) {
        e.preventDefault()
        this.messageErrorLengthAddress = true
      } else {
        this.messageErrorLengthAddress = false
      }
    },
    validateInputName(e) {
      if (
        e.keyCode === 47
        || e.keyCode === 61
        || e.keyCode === 58
        || e.keyCode === 59
      ) {
        e.preventDefault()
        this.messageErrorLengthName = true
      } else {
        this.messageErrorLengthName = false
      }
    },
    selectWarehouse(option, kompack) {
      if (kompack === 1) {
        if (option === 'ownWarehouse') {
          this.$refs['warehouse-options'].hide()
          this.addAddress()
        } else {
          this.$router.push({
            path: '/search-gudang',
          })
        }
      } else if (option === 'ownWarehouse') {
        this.$refs['warehouse-options'].hide()
        this.addAddress()
      } else {
        window.open('https://kompack.id/user-komship', '_blank')
      }
    },
    goBack() {
      this.editMode = false
    },
    setCustomerPhone(newVal) {
      this.customerPhone = newVal.customerPhone
      this.phoneUser = newVal.customerPhone
      this.messageErrorPhone = newVal.requireCustomerPhone
    },
    pasteDetailAddress(event) {
      event.preventDefault()
      const pasteText = (event.clipboardData || window.clipboardData).getData('text')
      const filteredText = pasteText.replace(/[/=:;]/g, '')
      this.fieldAddAddressDetail += filteredText
      this.addressDetail += filteredText
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
.kompack-logo{
  filter: grayscale(0%);
}
#partnerWarehouse:hover .kompack-logo {
  filter: grayscale(0);
}

.button__add__warehouse {
  position: fixed;
  right: 85px;
  bottom: 60px;
  z-index: 99;
}

.my-popover-class .arrow:after {
  border-top-color: red !important;
}
</style>
