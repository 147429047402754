<template>
  <div
    v-if="warehouses.length !== 0"
    class="border rounded-lg p-6"
  >
    <div class="font-semibold text-black text-xl mb-6">
      Gudang Mitra Kompack
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div
        v-for="(item, i) in warehouses"
        :key="i"
        class="border rounded-lg"
      >
        <div class="m-[12px]">
          <div class="flex gap-[10px] items-start">
            <img
              :src="item.warehouse_image_url === '' ? 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg' : item.warehouse_image_url"
              alt="placholder"
              width="69"
              height="69"
              class="rounded-lg h-[69px] w-[69px]"
            >
            <div class="w-full">
              <div class="flex items-center gap-[6px]">
                <img
                  src="https://storage.googleapis.com/komerce/assets/logo/logo-only/Logo-kompack.svg"
                  alt="kompack"
                  width="36"
                  height="36"
                >
                <div class="font-semibold text-black">
                  {{ item.warehouse_name }}
                </div>
                <img
                  v-if="item.is_main"
                  src="https://storage.googleapis.com/komerce/assets/svg/gudang-utama-orange.svg"
                  alt="utama"
                  width="26"
                  height="26"
                  class="mb-[2px] ml-[6px]"
                >
              </div>
              <div class="text-black text-[12px] !capitalize">
                {{ item.location.toLowerCase() }}
              </div>
              <div class="text-[#828282] text-[10px]">
                Berlangganan : {{ DAY_MONTH_YEAR(item.subscribed_date) }}
              </div>
            </div>
            <b-button
              variant="outline-primary"
              size="sm"
              @click="() => $router.push({ name: 'detail-gudang-kompack', params: { id: item.mitra_id } })"
            >Detail</b-button>
          </div>
        </div>
        <!-- <div class="flex items-center justify-between p-[12px] border-t">
          <div class="font-semibold text-black text-[12px]">
            Informasi Order
          </div>
          <b-input-group class="!w-auto">
            <flat-pickr
              v-model="month[i]"
              class="!rounded-l-lg !w-[100px] !font-semibold !text-black"
              :config="flatpickrMonthly"
              @on-change="onChangeMonth($event, item.warehouse_id)"
            />
            <b-input-group-append class="!border rounded-r-lg px-[5px] !border-[#d8d6de]">
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
                width="20"
                alt="calendar"
              >
            </b-input-group-append>
          </b-input-group>

        </div>
        <div class="border-t grid grid-cols-3">
          <div class="p-[14px]">
            <div class="text-[12px] text-black">
              Request Packing
            </div>
            <div class="text-[20px] text-black font-semibold my-[10px]">
              256
            </div>
            <div class="text-[10px] text-[#34A770]">
              Resi Komship : 66
            </div>
            <div class="text-[10px] text-[#08A0F7]">
              Resi Non Komship : 190
            </div>
          </div>
          <div class="p-[14px] border-x">
            <div class="text-[12px] text-black">
              Sukses Terpacking
            </div>
            <div class="text-[20px] text-black font-semibold my-[10px]">
              256
            </div>
            <div class="text-[10px] text-[#34A770]">
              Resi Komship : 66
            </div>
            <div class="text-[10px] text-[#08A0F7]">
              Resi Non Komship : 190
            </div>
          </div>
          <div class="p-[14px]">
            <div class="text-[12px] text-black">
              Sukses Terkirim
            </div>
            <div class="text-[20px] text-black font-semibold my-[10px]">
              256
            </div>
            <div class="text-[10px] text-[#34A770]">
              Resi Komship : 66
            </div>
            <div class="text-[10px] text-[#08A0F7] flex items-center gap-[4px]">
              Resi Non Komship
              <img
                v-b-tooltip.hover.top="'Tidak dapat tracking resi karena orderan bukan dari Komship'"
                src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                width="14"
                alt="info"
              >
            </div>
          </div>
        </div> -->
        <div class="border-t">
          <div class="font-semibold text-black text-[12px] p-[12px]">
            Data Produk
          </div>
          <b-table
            :fields="fields"
            :items="item.product"
            sticky-header="230px"
            class="!mb-0"
            show-empty
          >
            <template #cell(product)="data">
              <div
                class="flex items-start gap-[10px]"
                style="width: 200px !important;"
              >
                <img
                  :src="data.item.product_image_url ? data.item.product_image_url : `https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg`"
                  alt="product"
                  width="40"
                  height="40"
                  class="rounded-lg h-[40px] w-[40px]"
                >
                <div>
                  <div class="text-black font-medium text-[12px]">
                    {{ data.item.product_name }}
                  </div>
                  <div class="text-[10px] text-[#F95031]">
                    SKU : {{ data.item.product_sku }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(variant)="data">
              <div style="width: 100px !important;">
                <div
                  v-for="(variant, index) in (showMore[`${data.item.product_warehouse_id}&${data.item.product_id}`] ? data.item.variants : [data.item.variants[0]])"
                  :key="index"
                  class="flex items-center gap-[10px]"
                >
                  <div>
                    <div class="text-black text-[12px]">
                      {{ variant.variant }}
                    </div>
                  </div>
                </div>
                <div v-if="data.item.variants.length > 1">
                  <div
                    class="cursor-pointer text-[10px] text-[#333333] mt-[10px] font-semibold"
                    @click="() => $set(showMore, `${data.item.product_warehouse_id}&${data.item.product_id}`, !showMore[`${data.item.product_warehouse_id}&${data.item.product_id}`])"
                  >
                    <div v-if="showMore[`${data.item.product_warehouse_id}&${data.item.product_id}`]">
                      Sembunyikan <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-up-2.svg"
                        class="inline w-[15px]"
                        alt="up"
                      >
                    </div>
                    <div v-else>
                      Tampilkan <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                        class="inline w-[15px]"
                        alt="down"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(stock)="data">
              <div
                v-for="(variant, index) in (showMore[`${data.item.product_warehouse_id}&${data.item.product_id}`] ? data.item.variants : [data.item.variants[0]])"
                :key="index"
                class="text-[12px] flex items-start"
                :class="variant.product_stock <= 10 ? 'text-[#FBA63C]' : 'text-black'"
              >
                <div class="w-[24px]">
                  {{ variant.product_stock }}
                </div>
                <img
                  v-if="variant.product_stock <= 10"
                  v-b-tooltip.hover.top="'Stok produk kamu hampir habis'"
                  src="https://storage.googleapis.com/komerce/assets/svg/warning-Info-circle.svg"
                  class="mt-[1px]"
                  width="14"
                  alt="warning"
                >
              </div>
            </template>
            <template #empty>
              <div class="flex items-center justify-center flex-col gap-[16px] py-[12px]">
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/maximize-circle.svg"
                  alt="maximize-circle"
                  width="50"
                  class="opacity-20"
                >
                <div class="text-[12px] text-[#c2c2c2] text-center">
                  <div v-if="item.status_inbound === 'proceed'">
                    Pengajuan Inbound kamu sedang dalam proses
                  </div>
                  <div v-else>
                    Kamu belum menyimpan produk di gudang ini. Segera lakukan pengajuan Inbound
                  </div>
                </div>
                <b-button
                  size="sm"
                  :variant="item.status_inbound === 'proceed' ? 'secondary' : 'primary'"
                  :disabled="item.status_inbound === 'proceed'"
                  @click="$router.push({ name: 'ajukan-inbound', query: { warehouse: item.warehouse_id } })"
                >
                  Ajukan Inbound
                </b-button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import { DAY_MONTH_YEAR } from '@/libs/formatDate'
import { today } from '@/store/helpers'

import MonthSelect from 'flatpickr/dist/plugins/monthSelect'
import { Indonesian } from 'flatpickr/dist/l10n/id'
import flatPickr from 'vue-flatpickr-component'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import moment from 'moment'

export default {
  components: {
    // flatPickr,
  },
  data() {
    return {
      DAY_MONTH_YEAR,
      month: [],
      showMore: {},
      fields: [
        {
          key: 'product', label: 'Produk', thClass: '!capitalize !bg-[#f4f4f4] !text-black font-medium !px-[12px]', tdClass: '!px-[12px] align-top',
        },
        {
          key: 'variant', label: 'Variasi', thClass: '!capitalize !bg-[#f4f4f4] !text-black font-medium !px-[12px]', tdClass: '!px-[12px] align-top',
        },
        {
          key: 'stock', label: 'Stok', thClass: '!capitalize !bg-[#f4f4f4] !text-black font-medium !px-[12px]', tdClass: '!px-[12px] align-top',
        },
      ],
      warehouses: [],
      flatpickrMonthly: {
        mode: 'single',
        locale: Indonesian,
        altInput: true,
        maxDate: today,
        plugins: [
          new MonthSelect({
            shorthand: true,
            dateFormat: 'Y-m-d',
            altFormat: 'M Y',
          }),
        ],
      },
    }
  },
  mounted() {
    this.getWarehouse()
  },
  methods: {
    async getWarehouse() {
      try {
        const res = await newAxiosIns.get('komship/api/v1/subscribe-warehouses/partner')
        const { data } = res.data

        this.warehouses = data.map(wh => ({
          ...wh,
          product: [],
        }))

        this.month = this.warehouses.map(() => moment().format('YYYY-MM-DD'))

        await this.getProduct()
        // await this.getOrderInformation()
      } catch (err) {
        this.logError('Error in getWarehouse:', err)
      }
    },
    async getProduct() {
      try {
        const productRequests = this.warehouses.map(async wh => {
          const res = await newAxiosIns.get(`komship/api/v1/subscribe-warehouses/${wh.warehouse_id}/products`)
          const { data } = res.data

          const groupedProducts = this.groupProducts(data)
          if (wh.status_inbound === 'done') {
            // eslint-disable-next-line no-param-reassign
            wh.product = Object.values(groupedProducts)
          } else {
            // eslint-disable-next-line no-param-reassign
            wh.product = []
          }
        })

        await Promise.allSettled(productRequests)
      } catch (err) {
        this.logError('Error in getProduct:', err)
      }
    },
    async getOrderInformation() {
      try {
        const orderRequests = this.warehouses.map(async (wh, i) => {
          const res = await newAxiosIns.get(`komship/api/v1/subscribe-warehouses/${wh.warehouse_id}/order-information`, {
            params: {
              date: this.month[i],
            },
          })
          const { data } = res.data
        })

        await Promise.allSettled(orderRequests)
      } catch (err) {
        this.logError('Error in getOrderInformation:', err)
      }
    },
    async onChangeMonth(event, id) {
      const { startDate, endDate } = this.getStartAndEndDate(event[0])

      try {
        const res = await newAxiosIns.get(`komship/api/v1/subscribe-warehouses/${id}/order-information`, {
          params: {
            startDate,
            endDate,
          },
        })
        const { data } = res.data
      } catch (err) {
        this.logError(`Error fetching order information for warehouse ${id}:`, err)
      }
    },
    groupProducts(data) {
      return data.reduce((acc, product) => {
        const { product_id, product_stock, variant } = product
        const key = `${product_id}`

        if (!acc[key]) {
          acc[key] = { ...product, variants: [] }
          delete acc[key].variant
          delete acc[key].product_stock
        }

        acc[key].variants.push({
          variant: variant || '-',
          product_stock,
        })

        return acc
      }, {})
    },
    getStartAndEndDate(date) {
      const parsedDate = new Date(date)
      const year = parsedDate.getFullYear()
      const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0')

      const startDate = `${year}-${month}-01`
      const lastDay = new Date(year, parsedDate.getMonth() + 1, 0).getDate()
      const endDate = `${year}-${month}-${lastDay.toString().padStart(2, '0')}`

      return { startDate, endDate }
    },
    logError(message, error) {
      console.error(message, error)
    },
  },
}
</script>

<style>
.flatpickr-monthSelect-month.selected{
  background-color: #F95031 !important;
}
.flatpickr-monthSelect-month{
    font-weight: 600 !important;
}
.flatpickr-current-month input.cur-year{
    font-weight: 600 !important;
}
</style>
